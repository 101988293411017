/*------------------------------------------------------------------
[16. Tables and Datatables]
*/

/* Generic Tables 
------------------------------------
*/
.table.shrink {
  td:last-child{
    white-space: nowrap;
    width: 1px;
  }
}
.table {
  margin-bottom: 5px;
  margin-top: 5px;
  thead {
    // position: sticky;
    // top: 59px;
    // background: white;
    // box-shadow: inset 0 1px 0 #eceeef, inset 0 -1px 0 #eceeef;
    // z-index: 1;
    tr {
      th {
        text-transform: uppercase;
        font-weight: 700;
        font-family: $secondary-font-family;
        font-size: 12px;
        letter-spacing: 0.06em;
        padding-top: 14px;
        padding-bottom: 14px;
        vertical-align: middle;
        border-bottom: 1px solid fade($color-contrast-low, 70%);
        color: $color-contrast-high;
        border-top: none;
        &[class*="sorting_"]:not([class="sorting_disabled"]) {
          color: $color-contrast-high;
        }
        &:first-child {
          @include padding-left(18px !important);
        }
        .btn {
          margin-top: -20px;
          margin-bottom: -20px;
        }
      }
    }
  }
  tbody {
    tr {
      td {
        background: $color-contrast-lowest;
        color: $color-contrast-high;
        border-bottom: 1px solid fade($color-contrast-low, 70%);
        border-top: 0px;
        padding: 20px;
        font-size: 13.5px;
       
       
        .btn-tag {
          margin: 5px;
          &:hover {
            background: fade($color-contrast-higher, 15%);
          }
        }
        &[class*="sorting_"] {
          color: $color-contrast-high;
        }
        .checkbox label::after {
          @include left(0.5px);
        }
      }
      &.selected td {
        background: $color-warning-lighter;
        color: text-contrast($color-warning-lighter);
      }
    }
  }
  &.table-hover {
    tbody {
      tr {
        &:hover td {
          background: $color-complete-lighter !important;
          color: text-contrast($color-complete-lighter);
        }
        &.selected:hover td {
          background: $color-warning-lighter !important;
          color: text-contrast($color-warning-lighter);
        }
      }
    }
  }
  &.table-striped {
    tbody {
      tr {
        td {
          background: $body-background !important;
        }
        &:nth-child(2n + 1) td {
          background: $color-contrast-lowest !important;
        }
      }
    }
  }
  &.table-borderless {
    tbody {
      tr {
        td {
          border-top: 0;
        }
      }
    }
  }
  &.table-condensed {
    table-layout: fixed;
    thead {
      tr {
        th {
          @include padding-left(20px);
          @include padding-right(20px);
        }
      }
    }
    tbody {
      tr {
        td {
          padding-top: 12px;
          padding-bottom: 12px;
        }
      }
    }
    thead tr th,
    tbody tr td,
    tbody tr td *:not(.dropdown-default) {
      white-space: nowrap;
      vertical-align: middle;
      overflow: hidden;
      text-overflow: ellipsis;
      &.reset-overflow * {
        overflow: initial !important;
      }
    }
    &.table-detailed {
      & > tbody > tr {
        &.shown {
          & > td {
            background: $color-warning-lighter;
            &:first-child:before {
              @include rotate(90deg);
            }
          }
          & + tr {
            & > td {
              background: fade($body-background, 40%);
              padding: 0px 40px;
              .table-inline {
                margin-top: 13px;
                margin-bottom: 13px;
                background: transparent;
                tr,
                td {
                  background: transparent;
                  font-weight: 600;
                }
              }
            }
          }
        }
        &.row-details {
          & > td {
            &:first-child:before {
              content: "";
              display: none;
            }
          }
        }
        & > td {
          &:hover {
            cursor: pointer;
          }
          &:first-child:before {
            content: "\e988";
            display: inline-block;
            font-size: 16px;
            top: -1px;
            position: relative;
            @include margin-right(8px);
            font-family: "pages-icon";
            @include transition(all 0.12s linear);
          }
        }
      }
      .table-inline {
        td {
          border: none;
          text-align: left;
        }
      }
    }
  }
  &.table-borderless {
    & > tbody {
      & > tr {
        & > td {
          border-bottom: 0px;
        }
      }
    }
  }
}
.fht-table {
  margin-bottom: 0 !important;
}

/* Data-tables 
------------------------------------
*/

.table.dataTable.no-footer {
  border: none;
}
.dataTables_scroll {
  &:hover {
    .dataTables_scrollBody:before {
      content: "";
      top: 0;
      height: 0;
    }
  }
}
.dataTables_scrollBody {
  overflow-y: auto;
  border: none !important;
  &:before {
    content: "";
    position: absolute;
    @include left(0);
    @include right(0);
    top: 60px;
    bottom: 0;
    background: transparent;
  }
}
.dataTables_wrapper .dataTables_paginate {
  @include float(right);
}
.dataTables_wrapper .dataTables_paginate ul > li.disabled a {
  opacity: 0.5;
}
.dataTables_wrapper .dataTables_paginate ul > li > a {
  padding: 5px 10px;
  color: $color-contrast-high;
  opacity: 0.35;
  @include transition(opacity 0.3s ease);
  &:hover {
    opacity: 0.65;
  }
}

.dataTables_wrapper .dataTables_paginate ul > li.next > a,
.dataTables_wrapper .dataTables_paginate ul > li.prev > a {
  opacity: 1;
}
.dataTables_wrapper .dataTables_paginate ul > li.disabled a {
  opacity: 0.35;
  &:hover {
    opacity: 0.35;
  }
}
.dataTables_wrapper .dataTables_info,
.dataTables_wrapper .dataTables_paginate {
  margin-top: 25px !important;
}
.dataTables_paginate.paging_bootstrap.pagination {
  padding-top: 0;
  @include padding-right(20px);
}
.dataTables_wrapper .dataTables_info {
  clear: none;
  font-size: 12px;

  padding: 0 33px;
  color: $color-contrast-high;
}
.dataTables_wrapper .dataTables_paginate ul > li {
  display: inline-block;
  @include padding-left(0);
  font-size: 11px;
}
.dataTables_scrollHeadInner {
  @include padding-right(0 !important);
}
.export-options-container {
  position: relative;
}
.dataTables_wrapper .dataTables_paginate ul > li.active > a {
  font-weight: bold;
  color: $color-contrast-high;
  opacity: 1;
}
.export-options-container a {
  color: inherit;
  opacity: 1;
}
.exportOptions .DTTT.btn-group a {
  display: block !important;
}

table.dataTable thead {
  .sorting_asc:after {
    // background-image: url("../img/icons/sort_asc.png");
  }
  .sorting_desc:after {
    // background-image: url("../img/icons/sort_desc.png");
  }
  .sorting:after {
    // background-image: url("../img/icons/sort_both.png");
  }
  .sorting_asc_disabled:after {
    // background-image: url("../img/icons/sort_asc_disabled.png");
  }
  .sorting_desc_disabled:after {
    // background-image: url("../img/icons/sort_desc_disabled.png");
  }
}
table .table-actions {
  display: flex;
  gap: .5rem;
  text-align: right;
  justify-content: end;
}

table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc_disabled:after {
  bottom: 12px;
  content: "";
  width: 19px;
  height: 19px;
  background-position: center center;
  opacity: 1;
}

table.dataTable.table-condensed .sorting:after,
table.dataTable.table-condensed .sorting_asc:after,
table.dataTable.table-condensed .sorting_desc:after {
  top: 12px;
}

/* Demo classes */
.demo-table-search {
  thead th {
    &:nth-child(1) {
      width: 20%;
    }
    &:nth-child(2) {
      width: 22%;
    }
    &:nth-child(3) {
      width: 24%;
    }
    &:nth-child(4) {
      width: 15%;
    }
    &:nth-child(5) {
      width: 19%;
    }
  }
}

.demo-table-dynamic {
  thead th {
    &:nth-child(1) {
      width: 25%;
    }
    &:nth-child(2) {
      width: 30%;
    }
    &:nth-child(3) {
      width: 20%;
    }
    &:nth-child(4) {
      width: 25%;
    }
  }
}

.table-invoice {
  th {
    border-top: 0;
  }
}

.dataTables_wrapper {
  .row {
    width: 100%;
    & > div {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      width: 100%;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
      flex-direction: row-reverse;
    }
  }
  &#tableWithDynamicRows_wrapper {
    & > div {
      width: 100%;
    }
  }
}

/* Responsive Handlers : Tables */
@media (max-width: 991px) {
  .dataTables_wrapper .dataTables_info {
    @include float(left);
  }
  .dataTables_paginate.paging_bootstrap.pagination {
    @include float(right);
  }
}

@media (max-width: 767px) {
  .table-responsive {
    // border: 1px solid #ddd;
  }
  .dataTables_wrapper {
    .row {
      margin: 0;
      & > div {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
      }
    }
  }
  .dataTables_wrapper .dataTables_info,
  .dataTables_wrapper .dataTables_paginate {
    margin-top: 0 !important;
    @include padding-left(0);
    @include padding-right(0);
    margin-bottom: 10px;
    ul {
      padding: 0;
    }
  }
}

@media (max-width: 480px) {
  .dataTables_wrapper .dataTables_info,
  .dataTables_wrapper .dataTables_paginate {
    float: none;
    @include text-align(left);
    clear: both;
    display: block;
  }
}


@media only screen and (min-width: 980px) {

  .table {
    margin-bottom: 5px;
    margin-top: 5px;
    thead {
      position: sticky;
      top: -1px;
      background: white;
      box-shadow: inset 0 1px 0 #eceeef, inset 0 -1px 0 #eceeef;
      z-index: 1;
    }
    &.sticky {
      thead {
        top: 59px;
      }
    }
  }
}