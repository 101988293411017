/*------------------------------------------------------------------
[22. Login]
*/

.login-wrapper {
  background-color: $color-primary;
  background: url("#{$base-img-url}/login-bg.webp") no-repeat scroll
    0 0 transparent;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
  & > * {
    height: 100%;
  }
  .bg-pic {
    position: absolute;
    width: 100%;
    overflow: hidden;
    & > img {
      height: 100%;
      opacity: 0.6;
    }
  }
  .login-container {
    width: 496px;
    display: block;
    position: relative;
    @include float(right);
  }
  .bg-caption {
    width: 500px;
  }
}
.register-container {
  width: 550px;
  margin: auto;
  height: 100%;
}

/* Responsive handlers : Login
------------------------------------
*/

@media (max-width: 768px) {
  .login-wrapper {
    .login-container {
      width: 100%;
    }
  }
  .register-container {
    width: 100%;
    padding: 15px;
  }
}

@media only screen and (max-width: 321px) {
  .login-wrapper {
    .login-container {
      width: 100%;
    }
  }
}
