/*------------------------------------------------------------------
Pages v5.0
Theme : Corporate
Generated From SCSS
[Table of contents]

1. Layouts
2. View Ports
3. Chat
4. Panels
5. Typography
6. Buttons
7. Alerts
8. Notifications
9. Progress Indicators
10. Modals
11. Tabs & Accordians
12. Sliders
13. Treeview
14. Nestables
15. Form Elements
16. Tables & Datatables
17. Charts
18. Lists
19. Social App
20. Email App
21. Calendar App
22. Login
23. Lockscreen
24. Timeline
25. Gallery
26. Vector Map
27. Widgets
28. Misc
29. Print
30. Demo
-------------------------------------------------------------------*/
//Direction
$direction: ltr; // use `rtl` or `ltr

@import "./direction.scss";

// Color functions
@import "./color.scss";

// Mixins tools
@import "./mixins.scss";

// Import Vars
@import "./var.scss";

// Includes of Modules
@import "./modules.scss";

// Responsive Util
@import "./responsive.scss";

// Custom
// @import "custom";

.App {
  height: 100%;
}

.vh-100 {
  min-height: 100vh !important;
}

.rbt-menu {
  margin: unset !important;
}

.telemetry-dropdown {
  position: absolute;
  width: 300px;
  z-index: 1;
}

input[type="datetime-local"]::-webkit-inner-spin-button,
input[type="datetime-local"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

input[type="datetime-local"]::-moz-inner-spin-button,
input[type="datetime-local"]::-moz-calendar-picker-indicator {
  display: none !important;
  -moz-appearance: none;
}

.tooltip-visible {
  z-index: 9999 !important;
}


.modal-content .modal-top {
  padding: 25px 25px 0px 25px;
}

.modal-content .modal-top p {
  color: #8b91a0;
}

.modal-content .modal-body {
  padding: 0px 25px 25px 25px;
}

.spinner {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99999;
}

.notifications {
  position: absolute;
  right: 40px;
  top: 80px;
  z-index: 99999999;

  >.alert {
    cursor: pointer;
  }
}

.react-tooltip {
  z-index: 2;
}

.content-center {
  display: flex;
  justify-content: center;
  align-items: center;
}